<template>
  <div data-cy="asset-accounts-consumptions-page">
    <TabTitle class="mb-4" icon="fa-chart-column" action-position="right">
      <template>{{ account.type !== 'solar' ? 'Consumption' : 'Generation' }}</template>
      <template #sub-title>View {{ account.type !== 'solar' ? 'consumption' : 'generation' }} data relating to this account.</template>
    </TabTitle>

    <div class="bg-lighter rounded-md px-4 py-3 mb-4">
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center mr-3 border-right pr-3">
          <div class="form-static-text pr-3">Date Range</div>
          <FormItem
            id="dateRange"
            v-model="filters.dateRange"
            type="dateRangePicker"
            :disabled="loadingAction.getConsumptions"
            class="mb-0"
            date-format="DD/MM/YYYY"
            @input="() => refreshGraph()"
          />
        </div>
        <div class="d-flex flex-grow-1 align-items-center justify-content-end">
          <a href="#" class="font-w600" @click.prevent="showMoreOptions = !showMoreOptions"
            >{{ showMoreOptions ? 'Hide' : 'More' }} Options
            <i class="fa fa-chevron-down ml-1" :class="showMoreOptions ? 'fa-chevron-down' : 'fa-chevron-right'"></i
          ></a>
        </div>
      </div>
      <div v-if="showMoreOptions" class="pt-4">
        <DataTableColumns v-model="columns" @input="onColumnChange" />
      </div>
    </div>

    <div>
      <DataTable
        :items="consumption"
        :total="consumptionTotal"
        :loading="consumptionLoading.getConsumption"
        :columns="columns"
        :page-size="50"
        :fetch-items="getConsumption"
        :other-params="tableParams"
        default-sort-key="startTimestamp"
        :default-sort-direction="-1"
      >
        <template v-slot:startTimestamp="slotProps">
          {{ slotProps.data.startTimestamp | date('DD/MM/YYYY HH:mm:ss') }}
        </template>

        <template v-slot:endTimestamp="slotProps">
          {{ slotProps.data.endTimestamp | date('DD/MM/YYYY HH:mm:ss') }}
        </template>

        <template v-slot:isApportioned="slotProps">
          {{ slotProps.data.isApportioned ? 'Yes' : 'No' }}
        </template>

        <template v-slot:isEstimated="slotProps">
          {{ slotProps.data.isEstimated ? 'Yes' : 'No' }}
        </template>

        <template v-slot:isGridData="slotProps">
          {{ slotProps.data.isGridData ? 'Yes' : 'No' }}
        </template>

        <template v-slot:isHalfHourly="slotProps">
          {{ slotProps.data.isHalfHourly ? 'Yes' : 'No' }}
        </template>
      </DataTable>
    </div>

    <div v-if="account.type !== 'waste'">
      <div v-if="analyticsLoading.getAnalytics['consumption']" class="mb-4">
        <Spinner />
      </div>

      <div v-else-if="analytics['consumption'].data?.length">
        <!-- <apexchart class="mb-4" height="400" :options="consumptionColumnChart.options" :series="consumptionColumnChart.series" /> -->
      </div>

      <div v-else class="alert alert-warning" role="alert">
        <p class="mb-0">No chart data found{{ Object.keys(filters).some(key => filters[key]) ? ' within the selected filters' : '' }}.</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import DataTable from '@/components/DataTable';
import DataTableColumns from '@/components/DataTableColumns';
import FormItem from '@/components/FormItem';
import Spinner from '@/components/SpinnerLogo';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'AssetAccountConsumption',
  components: {
    DataTable,
    DataTableColumns,
    FormItem,
    Spinner,
    TabTitle
  },
  data() {
    return {
      showAdd: false,
      initialLoad: true,
      importStatus: 'pending',
      form: {
        timestamp: moment().format('DD/MM/YYYY HH:mm'),
        value: ''
      },
      filters: {
        dateRange: [moment().subtract(1, 'year').startOf('day').toDate(), moment().endOf('day').toDate()]
      },
      showMoreOptions: false,
      columns: [
        { name: 'Period Start', code: 'startTimestamp', sortable: true, hidden: false },
        { name: 'Period End', code: 'endTimestamp', sortable: true, hidden: false },
        { name: 'Value', code: 'value', sortable: true, hidden: false },
        { name: 'Apportioned From', code: 'apportionedFrom', sortable: true, hidden: true },
        { name: 'Apportioned?', code: 'isApportioned', sortable: true, hidden: true },
        { name: 'Estimated?', code: 'isEstimated', sortable: true, hidden: true },
        { name: 'Grid Data?', code: 'isGridData', sortable: true, hidden: true },
        { name: 'Half Hourly?', code: 'isHalfHourly', sortable: true, hidden: true },
        { name: 'Conversion Factor', code: 'factor', sortable: true, hidden: true },
        { name: 'Units', code: 'unit', sortable: true, hidden: true },
        { name: 'Read Type', code: 'readType', sortable: true, hidden: false },
        { name: 'Source', code: 'source', sortable: true, hidden: false },
        { name: 'User', code: 'userSub', sortable: true, hidden: false }
      ]
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      consumption: 'consumption/consumption',
      consumptionTotal: 'consumption/total',
      consumptionLoading: 'consumption/loadingAction',
      account: 'asset/selectedAccount',
      loadingAction: 'account/loadingAction',
      errorAction: 'account/errorAction',
      analytics: 'analytics/analytics',
      analyticsLoading: 'analytics/loadingAction'
    }),
    consumptionStartDate() {
      return moment(this.filters.dateRange[0], 'DD/MM/YYYY');
    },
    consumptionEndDate() {
      return moment(this.filters.dateRange[1], 'DD/MM/YYYY');
    },
    tableParams() {
      return {
        accountId: this.account._id,
        'startTimestamp.lte': this.consumptionEndDate.format('YYYY-MM-DD'),
        'endTimestamp.gte': this.consumptionStartDate.format('YYYY-MM-DD')
      };
    }
  },
  beforeMount() {
    if (this.$route.query.columns) {
      const showColumns = this.$route.query.columns.split(',');

      this.columns = this.columns.map(c => ({
        ...c,
        hidden: !showColumns.includes(c.code)
      }));
    }

    if (this.$route.query.filters) {
      this.filters = this.$route.query.filters.split('|').reduce((acc, filter) => {
        const [key, value] = filter.split(':');

        return {
          ...acc,
          [key]: value
        };
      }, this.filters);
    }

    if (this.$route.query.dateRange) {
      const [startDate, endDate] = this.$route.query.dateRange.split('|');

      this.filters.dateRange = [moment(startDate, 'YYYY-MM-DD').toDate(), moment(endDate, 'YYYY-MM-DD').toDate()];
    }
  },
  mounted() {
    this.refreshGraph();
  },
  destroyed() {
    this.reset();
  },
  methods: {
    ...mapActions({
      getConsumption: 'consumption/list',
      download: 'account/downloadConsumption',
      getAnalyticsV2: 'analytics/getAnalyticsV2'
    }),
    ...mapMutations({
      reset: 'account/RESET_STATE'
    }),
    async refreshGraph() {
      const params = {
        startDate: this.consumptionStartDate.format('YYYY-MM-DD'),
        endDate: this.consumptionEndDate.format('YYYY-MM-DD'),
        granularity: this.filters.granularity,
        source: this.filters.source
      };

      if (this.account.type === 'waste') {
        params.$select = 'category,type,unit';
        // await this.getConsumptions({ data: { params }, accountId: this.account._id });
      } else {
        this.getAnalyticsV2({
          id: 'consumption',
          params: {
            id: 'consumption',
            ...params,
            dataType: 'account',
            accountId: this.account._id
          }
        });
      }

      this.onUpdateQueryString();
    },
    onUpdateQueryString() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          columns: this.columns
            .filter(c => !c.hidden)
            .map(c => c.code)
            .join(','),
          filters: Object.entries(this.filters)
            .map(([key, value]) => `${key}:${value}`)
            .filter(f => !f.startsWith('dateRange:'))
            .join('|'),
          dateRange: this.consumptionStartDate.format('YYYY-MM-DD') + '|' + this.consumptionEndDate.format('YYYY-MM-DD')
        }
      });
    }
  }
};
</script>
